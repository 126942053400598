import React, {useEffect, useState} from "react";
import ZDrawer from "@/components/Drawer";
import CostEX from "@/pages/home/sections/quote/components/cost";
import SurchargeList from "@/pages/skill/surcharge/table";
import {Api} from "@/api";
import './index.less'
import {logisticsMap} from "@/pages/order/interface";

interface Props {
    item: any
    onOrder?: any
}

const ChannelDetail = (props: Props) => {

    const {item, onOrder} = props
    const [visible, setVisible] = useState<boolean>(false)
    const [detail, setDetail] = useState<any>({})
    const [countryMap, setCountryMap] = useState<Record<string, string>>({})

    const onDetail = () => {
        Api.Channel.GetDetailById({id: item.id}).then((res: any) => {
            setDetail(res.data)
            setVisible(true)
        })
    }

    useEffect(() => {
        console.log(item)
    }, [item])
    return (
        <>
            <span className='f14 color-base fang-an' onClick={onDetail}>查看方案 &gt;</span>
            <ZDrawer visible={visible} onClose={() => setVisible(false)} width={1000}>
                <div className='route-detail-name'>
                    <div>
                        <div className='f18 mb15'>{detail.name}</div>
                        {detail.advantage && <span className='tag color-org f14'>{detail.advantage}</span>}
                    </div>
                    <button className="search_btn_bj" onClick={onOrder}>立即下单</button>
                </div>
                <div className="route-detail-info">
                    <div className='mb20 info-item'> 运输方式：&nbsp;{logisticsMap[detail?.method]}  </div>
                    <div className='mb20 info-item'> 体积系数：&nbsp;{detail?.factor}  </div>
                    <div className='mb20 info-item'> 分泡比例：&nbsp;{detail?.divide * 100} %</div>
                    <div className='mb20 info-item'> 航运周期：&nbsp;{detail?.dayDescribe}  </div>
                    <div className='info-item'>
                        <div className='item-label'>方案备注：</div>
                        <div className='item-content flex align-items-center flex-wrap'>
                            {detail?.remarks?.map((i: string, index: number) =>
                                <span style={{whiteSpace: 'nowrap'}} key={index}>{i}；</span>)}
                        </div>
                    </div>
                </div>
                <CostEX item={item} type={2}/>
                {detail.specials && detail.specials?.length &&
                <div className='route-detail-surcharge'>
                    <div className='surcharge-title'>附加费</div>
                    <SurchargeList specials={detail.specials} setCountryMap={setCountryMap} countryMap={countryMap}/>
                </div> || ''
                }
                {
                    detail?.content &&
                    <div className='route-detail-surcharge'>
                        <div className='surcharge-title'>渠道说明</div>
                        <div className='color-666 f16' dangerouslySetInnerHTML={{__html: detail?.content}}/>
                    </div>
                    || ''
                }
            </ZDrawer>
        </>
    )
}

export default ChannelDetail