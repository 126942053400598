import { AppRender } from "@/ZRender";
import React from "react";
import pages from '../../../page.json'
import Home from "./business";
import BaseApp from "@/pages/_global/index";
import QuoteSection from "@/pages/home/sections/quote";

AppRender(pages.pages["src/pages/home/index.tsx"], (props?: any) => {
    return (
        <BaseApp>
            {props.jsxPath === '/src/pages/home/business.tsx' && <Home {...props} />}
            {props.jsxPath === '/src/pages/home/sections/quote/index.tsx' && <QuoteSection/>}
        </BaseApp>
    )
})

