import React, {useEffect, useState} from "react";
import ChannelItem from "@/pages/home/sections/quote/components/channelItem";
import './index.less'
import {Api} from "@/api";
import {ISelect} from "@/components/Select/helper";


const QuoteSection = () => {
    const [country, setCountry] = useState<string>('')
    const [data, setData] = useState<any[]>([])
    const [items, setItems] = useState<ISelect[]>([])
    const [areaMap, setAreaMap] = useState<Record<string, string>>({})

    const init = () => {
        Api.Country.GetWareCountrys().then((res: any) => {
            const json = Object.keys(res.data).reduce((result: ISelect[], i: string) => {
                result.push({
                    label: res.data[i],
                    value: i
                })
                return result
            }, [])
            setItems(json)
        })
        Api.Country.GetAreaList2().then((res: any) => {
            const json = res.data.reduce((result: Record<string, string>, i: any) => {
                result[i.code] = i.name
                return result
            }, {})
            sessionStorage.setItem('areaMap', JSON.stringify(json))
            setAreaMap(json)
        })
    }
    const getData = () => Api.Channel.GetChannelList({countryCode: country || 'US'}).then((res: any) => {
        const json = res.data.reduce((result: any, item: any) => {
            const quotes = JSON.parse(item.quotes || '{}')
            result.push({
                ...item,
                quotetype: quotes.quotetype,
                quotes
            })
            return result
        }, [])
        setData(json)
    })

    useEffect(() => {
        init()
        if (sessionStorage.getItem('baojia')) {
            setCountry(sessionStorage.getItem('baojia') || '')
        } else {
            setCountry('US')
        }
    }, [])

    useEffect(() => {
        if (country) {
            getData()
        }

    }, [country])


    const empty = () => (
        <div className='flex flex-column align-items-center color-999 mt40'>
            <img src="/images/empty.png" alt=""/>
        </div>
    )

    const onClick = (row: any) => {
        sessionStorage.setItem('baojia', row.value)
        setCountry(row.value)
    }

    const getClass = (c: ISelect) => c.value === country && 'hot-tab zc-hot-tab-active' || 'hot-tab zc-hot-tab'
    return (
        <div className='zc-hot-wrapper'>
            <div className='zc-hot-tabs-wrapper'>
                {items.map((c: ISelect) =>
                    <span key={c.value} onClick={() => onClick(c)} className={getClass(c)}>{c.label}</span>)}
            </div>
            <div className='zc-hot-route-wrapper'>
                {
                    data.length && data?.map((item: any) => <ChannelItem key={item.id} item={item} areaMap={areaMap}/>)
                    ||
                    empty()
                }
                {data.length % 3 === 2 && <div className='zc-item-slot'/>}
            </div>
        </div>
    )
}
export default QuoteSection