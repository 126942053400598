import React from "react";
import './index.less'
import ZTable from "@/components/Table";
import {IColumn} from "@/components/Table/interface";

interface Props {
    source: any
}

const UnitPrice = (props: Props) => {
    const {source} = props

    const column: IColumn[] = [
        {title: '重量(kg)', key: 'weight', width: 80, render: (t: number) => t / 1000},
        {title: '总报价(元)', key: 'money'},
    ]
    return (
        <ZTable
            size='small'
            align='center'
            border
            pagination={false}
            column={column}
            data={source}/>
    )
}
export default UnitPrice