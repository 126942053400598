import FbaWareSelect from "@/components/FbaWareSelect";
import Modal from "@/components/Modal";
import SelectArea from "@/components/SelectArea";
import {object2Query} from "@/utils/ZUtils";
import {IRenderApp} from "@/ZRender/interface";
import React, {useEffect, useState} from "react";
import './index.less';

interface IProps extends IRenderApp {
    params?: any,
    trigger?: any,
    $: any,
    onCallBack?: any
}

const quick = [
    {
        name: '杭州市---FTW1---21KG',
        From: '330000/330100/330105',
        To: 'US||FTW1',
        Weight: 21
    },
    {
        name: '金华市---ONT8---102KG',
        From: '330000/330700/330782',
        To: 'US||ONT8',
        Weight: 102
    },
]


const Home = (props: IProps) => {
    const [filterOptions, setFilterOptions] = useState<boolean>()
    const {$} = props;
    const submitBtn = $('.search_btn_bj');
    submitBtn.onclick = () => {
        const inputDom = $('input');
        if (!filterOptions) {
            Modal.tips('请选择正确的目的地')
            return
        }
        const InputArray = Array.from(inputDom);
        const InputData: any = InputArray.reduce((t: any, item: any) => {
            t[item.name] = item.alt || item.value;
            return t;
        }, {});
        if (!InputData['From']) {
            Modal.tips('请选择起送地')
            return;
        }
        if (!InputData['To']) {
            Modal.tips('请选择目的地')
            return;
        }
        if (!InputData['Weight']) {
            Modal.tips('请输入总重量')
            return;
        }

        localStorage.setItem('homeQuery', JSON.stringify({
            From: InputData['From'],
            To: InputData['To'],
            Weight: InputData['Weight'],
        }))
        location.href = `/Home/MatchRoute${object2Query(InputData)}`
    }

    const [From, setFrom] = useState();
    const [To, setTo] = useState();

    useEffect(() => {
        const inputDom = $('input');
        const homeQuery = localStorage.getItem('homeQuery');
        if (homeQuery) {
            const queryObject = JSON.parse(homeQuery);
            if (!queryObject.To?.includes('||')) {
                localStorage.removeItem('homeQuery')
            } else {
                setFrom(queryObject.From);
                setTo(queryObject.To)
                inputDom[4].value = queryObject.Weight
            }
        }
    }, [])

    const onQuick = (item: any) => {
        const params = {
            From: item.From,
            To: item.To,
            Weight: item.Weight
        }
        location.href = `/Home/MatchRoute${object2Query(params)}`
    }


    return (
        <>
            <div style={{display: 'flex'}} className='align-items-center mt10'>
                <div className="fab_start fba-select-area-wrap">
                    <SelectArea label="起送地" fld='From' size='large' placeholder="请选择起送地" hotcity value={From}
                                onChange={(e: any) => setFrom(e.target.value)}/>
                </div>
                <div className="fab_end select-wrap">
                    <FbaWareSelect label="目的地" fld='To' display='grid' setFilterOptions={setFilterOptions}
                                   placeholder="选择FBA仓库" size='large' value={To}
                    />
                </div>
                <div className="search_kg fl" style={{width: 200}}>
                    <b className='f16'>总重量 :</b>
                    <input className='f16 zc-weight' type="text" autoComplete="off" name="Weight"/>
                    <em><span className='black'>KG</span></em>
                </div>
                <div className="search_btn ml20">
                    <button className="search_btn_bj f16" style={{whiteSpace: 'nowrap'}}>预估费用</button>
                </div>
            </div>
            <div className='test-quick mb10'>
                <div className='flex align-items-center'>
                    <div className='mr15'>历史搜索/热门 :</div>
                    {
                        quick.map((item: any, index: number) =>
                            <div key={index} className="quick-tags" onClick={() => onQuick(item)}>{item.name}</div>
                        )}
                </div>
            </div>
        </>
    )
}

export default Home;