export interface IGsProps {
    weight: number
    money: number
    profit?: number
    cost?: number
}

// interface IShips {
//     area: string
//     minweight: number
//     gs: IGsProps[]
// }

// quoteType == 2 单价表数据
export const initUnitData = (item: any) => {
    const {ships = []} = item.quotes
    const source = ships[0].gs
    const len = Math.ceil(source.length / 2)
    let first: IGsProps[] = []
    let second: IGsProps[] = []
    const l = source.length
    source.forEach((i: IGsProps, index: number) => {
        if (index < len) {
            if (index < 4) {
                first.push(i)
            }
        } else {
            if (index >= l - 4) {
                second.push(i)
            }
        }
    })
    return {
        first,
        second
    }
}


// quoteType == 2 单价表数据
export const initUnitData2 = (item: any) => {
    const {ships} = item.quotes
    const source = ships[0].gs
    const len = Math.ceil(source.length / 4)
    let a: IGsProps[] = []
    let b: IGsProps[] = []
    let c: IGsProps[] = []
    let d: IGsProps[] = []


    source.forEach((i: IGsProps, index: number) => {
        if (index < len) {
            a.push(i)
        }
        if (index >= len && index < 2 * len) {
            b.push(i)
        }
        if (index >= 2 * len && index < 3 * len) {
            c.push(i)
        }
        if (index >= 3 * len) {
            d.push(i)
        }
    })
    return {a, b, c, d}
}


// quoteType === 1 总价报数据
export const initTotalData = (item: any, show?: boolean) => {
    const {ships = []} = item.quotes
    let title: Record<number, number> = {}

    const json: any = ships.reduce((result: any, item: any) => {
        if (show) {
            const gs = JSON.stringify(item.gs)
            if (result[gs]) {
                result[gs] = {
                    ...result[gs],
                    area: [...result[gs].area, item.area]
                }
            } else {
                result[gs] = {
                    ...item,
                    area: [item.area]
                }
            }
        } else {
            result[item.area] = {
                ...item,
                area: [item.area]
            }
        }

        return result
    }, {})

    const _ships = Object.values(json)?.reduce?.((result: any, i: any) => {
        const gs = i.gs.reduce((subResult: Record<number, IGsProps>, sub: IGsProps) => {
            title[sub.weight] = sub.profit || 0
            subResult[sub.weight] = sub
            return subResult
        }, {})
        result.push({...i, ...gs})
        return result
    }, [])
    return {
        _ships,
        title
    }
}
