import React from "react";
import UnitPrice from "@/pages/home/sections/quote/components/unit";
import TotalPrice from "@/pages/home/sections/quote/components/total";
import {initTotalData, initUnitData, initUnitData2} from "@/pages/home/sections/quote/components/cost/interface";

interface Props<T = any> {
    item: T
    type?: 2
    areaMap?: Record<string, string>
}

const CostEX = (props: Props) => {
    const {item = {}, type = 1, areaMap} = props

    return (
        <>
            {
                item.quotetype === 2
                &&
                (
                    type === 1
                    &&
                    <div className='flex'>
                        <UnitPrice source={initUnitData(item).first}/>
                        <div className='mr15'/>
                        <UnitPrice source={initUnitData(item).second}/>
                    </div>
                    ||
                    <div className='flex'>
                        <UnitPrice source={initUnitData2(item).a}/>
                        <div className='mr15'/>
                        <UnitPrice source={initUnitData2(item).b}/>
                        <div className='mr15'/>
                        <UnitPrice source={initUnitData2(item).c}/>
                        <div className='mr15'/>
                        <UnitPrice source={initUnitData2(item).d}/>
                    </div>
                )
                ||
                <TotalPrice source={initTotalData(item)} areaMap={areaMap}/>
            }
        </>
    )
}

export default CostEX