import React, {useContext} from "react";
import './index.less'
import CostEX from "@/pages/home/sections/quote/components/cost";
import {AppContext, APPEvent} from "@/ZRender/context";
import {object2Query} from "@/utils/ZUtils";
import ChannelDetail from "@/pages/home/sections/quote/components/detail";

interface Props {
    item: any
    areaMap?: any
}

const ChannelItem = (props: Props) => {
    const {state, dispatch} = useContext<any>(AppContext);

    const {item, areaMap} = props

    const onOrder = (e: any) => {
        e.stopPropagation()
        if (state.isLogin === false) {
            dispatch({
                type: APPEvent.SHOWLOGIN,
                state: {showLogin: true}
            })
            return
        }
        const homeQuery = localStorage.getItem('homeQuery');
        const queryObject = JSON.parse(homeQuery || '{}');
        sessionStorage.removeItem('fields')
        sessionStorage.removeItem('topAddr')
        sessionStorage.removeItem('addr')
        sessionStorage.removeItem('boxPics')
        const TemplateName = item.name?.replace('%', '%25')
        location.href = `/order/createorder.html${object2Query({
            TemplateName,
            TemplateId: item.id,
            Method: item.method,
            from: queryObject?.From,
            country: item.countryCode
        })}`
    }

    return (
        <>
            <div className='zc-hot-route-item'>
                <div className='flex flex-column'>
                    <div className='flex justify-between'>
                        <span className='f16'> {item.name} </span>
                        <div className='o-btn' onClick={onOrder}>立即下单&gt;</div>
                    </div>
                    <div className='flex'>
                        <span className='f14 color-base mr15'>{item.dayDescribe}</span>
                        <ChannelDetail item={item} onOrder={onOrder}/>
                    </div>
                </div>
                <div className='zc-hot-table'>
                    <CostEX item={item} areaMap={areaMap}/>
                </div>
            </div>

        </>
    )
}
export default ChannelItem