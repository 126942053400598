import React, {useEffect, useState} from "react";
import ZTable from "@/components/Table";
import {IColumn} from "@/components/Table/interface";

interface Props {
    source: any
    areaMap?: any
}

const TotalPrice = (props: Props) => {
    const {source, areaMap = {}} = props
    const [data, setData] = useState<Record<string, string>>({})

    useEffect(() => {
        if (sessionStorage.getItem('areaMap')) {
            setData(JSON.parse(sessionStorage.getItem('areaMap') || ''))
        } else {
            setData(areaMap)
        }
    }, [])


    const getColumns = () => {
        const {title} = source
        return Object.keys(title).reduce((result: any, i: string) => {
            result.push({
                title: `${Number(i) / 1000}kg`,
                key: i,
                width: 50,
                render: (text: any) => text?.money || '/'
            })
            return result
        }, [])
    }

    const columns: IColumn[] = [
        {
            title: '区域/仓库',
            ellipsis: true,
            width: 75,
            key: 'area',
            render: (text: string[]) => text.map((i: string) => <span key={i}> {data[i] || i} </span>)
        },
        ...getColumns()
    ];

    return (
        <ZTable
            size='small'
            align='center'
            border
            pagination={false}
            column={columns}
            data={source?._ships}/>
    )
}

export default TotalPrice